import React from 'react'

export default function FclagerAbout() {
  return (
    <div className='container my-4'>
      <h1 className='py-2'> Om os</h1>
      
      <p className='lh-lg'>
        FCLager udlejer lagerrum til små erhvervslejere på adressen Pilevangsdal i Sengeløse, Taastrup, og har gjort dette siden 2006. FCLager blev etableret som et forretningsområde firmaet Flisecentrum natursten og fliser til privat kunder i egen netbutik. 
      </p>

      <p className='lh-lg'>
        Adressen Pilevangsdal 1, er blevet benyttet som et stort lager for Petek Trading ApS, der var en grossist virksomhed, der importerede natursten og fliser fra forskellige lande og udnyttede adressen som en distributions-hub i Skandinavien. Flisecentrum og Petek Trading var virksomheder med same ejer og adresse i Sengløse, og vi er stadig de samme ejere af Flisecentrum her små 20 år senere.
      </p>

      <p className='lh-lg'>
        Efter finanskrisen i 2008, lukkede Petek Trading ApS, og Flisecentrum tilføjede et nyt forretningsområde med udlejning lagerlokaler. 
      </p>

      <p className='lh-lg'>
        Siden 2008, er Flisecentrum vokset som udlejningsfirma, og er i dag lille lager udlejningsvirksomhed med sine 25 små lejere - primært erhverslejere men også enkelte private. Nogen lejere har været med os siden vi startede i 2006.
      </p>

      <p className='lh-lg'>
        Da Flisecentrum er registeret enkeltmand virksomhed med CVR nr. 29334706, og opererer nu primært som en udlejnings firma. FClager er navnet på den del af Flisecentrums forretning, der handler om udlejning.
      </p>

      <p className='lh-lg'>
        Alle officiel dokumentation, information og kommunikation foregår under og i Flisecentrums navn, logo, og cvr nummer. FCLager navnet på udlejningsforretningsområdet bruges primært til annoncering på nettet, hvor Flisecentrums navn kan være lidt forvirrende.
      </p>

    </div>
  )
}
