
const IMAGES = { 

  // ? Indoors images

  img_100: require('./2023_lager_i05.jpg'), 
  img_105: require('./2023_lager_i21.jpg'),  
  img_110: require('./2023_lager_i13.jpg'),
  img_115: require('./2023_lager_i17.jpg'),
  img_120: require('./2023_lager_i14.jpg'),
  img_125: require('./2023_lager_i39.JPG'),
  img_130: require('./2023_lager_i01.JPG'),
  img_135: require('./2023_lager_i03.JPEG'),


   // ? Outdoor images
   img_800: require('./2023_lager_u38.JPG'),
   img_805: require('./2023_lager_u21.JPG'),
   img_810: require('./2023_lager_u70.JPG'),


   // ? Outdoor container images
   img_900: require('./2023_lager_container_24.JPG'),
   img_905: require('./2023_lager_container_07.JPG'),
   img_910: require('./2023_lager_container_01.JPG'),
   img_915: require('./2023_lager_container_11.JPG'),
   img_920: require('./2023_lager_container_09.JPG'),
}

export default IMAGES;