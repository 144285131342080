import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import {Routes, Router, Route} from 'react-router-dom'
import FclagerHome from './components/FclagerHome';
import FclagerAbout from './components/FclagerAbout';
import FclagerPricing from './components/FclagerPricing';
import FclagerGallery from './components/FclagerGallery';
import FclagerContact from './components/FclagerContact';
import FclagerNavbar from './components/FclagerNavbar';
import FclagerFooter from './components/FclagerFooter';
import FclagerEmailSent from './components/FclagerEmailSent';
import FclagerInfo from './components/FclagerInfo';

function App() {
  return (
    <div>     
      <FclagerNavbar />      
        <Routes>
          <Route path="/" element={<FclagerHome/>} />
          <Route path="/about" element={<FclagerAbout/>} />
          <Route path="/pricing" element={<FclagerPricing/>} />
          <Route path="/info" element={<FclagerInfo/>} />
          <Route path="/gallery" element={<FclagerGallery/>} />
          <Route path="/contact" element={<FclagerContact/>} />
          <Route path="/email_sent" element={<FclagerEmailSent/>} />
        </Routes>        
      <FclagerFooter />
    </div>
  );
}

export default App;
