import React from 'react'
import { NavLink } from 'react-router-dom'

export default function FclagerInfo() {
  return (
    <div className='container'>
      {/* INFO SECTION */}
      <section>
        {/* Info om vilkaar og lejekontrakt.. */}
        <p className='text-center fs-1 mt-4'>Info</p>   

        <div class="d-flex justify-content-center">
              <div 
                class="card col-12 text-light"
                style={{backgroundImage: "linear-gradient(269deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}
              >
                <div class="card-body text-start pricebox">
             
                  <p>
                    <i class="bi bi-info-circle me-2 text-info"></i>Alle priser netto, eksklusive moms
                  </p>
            
                  <p>
                    <i class="bi bi-info-circle me-2 text-info  "></i> 3 mdrs depositum og 1 mdrs forudbetalt leje
                  </p>

                  <p>
                    <i class="bi bi-info-circle me-2 text-info"></i>Leje kontrakter kan laves til enten erhvervs eller privatkunder
                  </p>

                  <p>
                    <i class="bi bi-info-circle me-2 text-info  "></i> Lejemålet kan opsiges med 3 måneders varsel fra førstkommende 1. i for både lejer og udlejer  
                  </p>
                  
                    
                </div>
              </div>
            </div>

      </section>

      {/*QUIZ SECTION */}
      <section>
        {/* Here comes accordion */}
        <p className='text-center fs-3 mt-5'>Ofte Stillede Spørgsmål </p>

        <div class="accordion mt-3 mb-5" id="accordionExample">

          {/* <div class="accordion_1 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <span className='fw-semibold me-2'>1 </span> Er der adgangsbegrænsninger?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
              </div>
            </div>
          </div> */}

          <div class="accordion_1 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
              <span className='fw-semibold me-2'>1 </span> Er der adgangsbegrænsninger?
              </button>
            </h2>
            <div id="collapse1" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Nej. Men da vi  bor adressen vil vi gerne have en sms besked, hvis lejer besøger sit lager sent om aftenen. 
              </div>
            </div>
          </div>


          <div class="accordion_2 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
              <span className='fw-semibold me-2'>2 </span> Hvornår på måneden betales lejenBetaling? 
              </button>
            </h2>
            <div id="collapse2" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Den månedlige leje betales den 1. i hver måned.
              </div>
            </div>
          </div>

          <div class="accordion_3 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
              <span className='fw-semibold me-2'>3 </span> Er der adgang til affaldscontainer?
              </button>
            </h2>
            <div id="collapse3" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
              Nej, Lejeren skal selv søge for bortskaffelse af affald, der er ikke adgang til container. Der må ikke opbevares farligt affald (kemisk affald, brandfarlige materialer, gas tanke under tryk, spildolie/batteri mv.).
              </div>
            </div>
          </div>          

          <div class="accordion_4 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
              <span className='fw-semibold me-2'>4 </span> Hvad er parkeringsforholdene når jeg skal besøge mit lagerlokale?
              </button>
            </h2>
            <div id="collapse4" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
              Parkering må kun ske på det aftalte område, og kun mens lejer opholder sig på lejemålet. Køretøjer må ikke efterlades. Ophold ved port området tilladt i maks. 5 minutter. Det er også forbudt at parkere trailer eller lignende på adressen.
              </div>
            </div>
          </div>

          <div class="accordion_5 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
              <span className='fw-semibold me-2'>5 </span> Kan jeg bestille varer eller lignende til adressen?
              </button>
            </h2>
            <div id="collapse5" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Nej. Der må ikke bestilles varer til adressen. Ejendommen kan ikke tage imod varer og leveringer
              </div>
            </div>
          </div>

          <div class="accordion_6 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="true" aria-controls="collapse6">
              <span className='fw-semibold me-2'>6 </span> Hvordan kan jeg opsige mit lagerlokale?
              </button>
            </h2>
            <div id="collapse6" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Opsigelse skal altid ske skriftligt med brev eller en e-mail.   
              </div>
            </div>
          </div>

          <div class="accordion_7 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="true" aria-controls="collapse7">
              <span className='fw-semibold me-2'>7 </span> Er der adgang til toilet på adressen?
              </button>
            </h2>
            <div id="collapse7" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
              Nej, FClager er beregnet til opbevaring og ikke langvarig ophold. Der er ikke adgang til toilet     
              </div>
            </div>
          </div>

          <div class="accordion_8 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="true" aria-controls="collapse8">
              <span className='fw-semibold me-2'>8 </span> Hvor kan jeg se hvilke rum I har ledige?
              </button>
            </h2>
            <div id="collapse8" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Ring til os på <span className='fw-semibold'> 24 47 59 10 </span> for en snak om hvad vi har af aktuelle muligheder og ledige rum. Du kan se de forskellige typer af rum vi har.    
              </div>
            </div>
          </div>

          <div class="accordion_9 accordion-item">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse9" aria-expanded="true" aria-controls="collapse9">
              <span className='fw-semibold me-2'>9 </span> Hvor store er jeres rum?
              </button>
            </h2>
            <div id="collapse9" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                De spænder fra 9 m2 til 40m2   
              </div>
            </div>
          </div>

          <div className='text-center fs-3 mt-5'>
            <NavLink to="/" className="btn btn-dark btn-sm"><i class="bi bi-arrow-left-circle"></i> Hovedside</NavLink>          
          </div>
        </div>
      </section>

    </div>
  )
}
