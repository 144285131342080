import React from 'react'
import { NavLink } from 'react-router-dom'

export default function FclagerPricing() {
  return (
    
    <div className='container mt-4 '>
      <p className='text-center fs-1 py-4'>Priser</p>
     
      <section >
        <div className="">
          <div className="row text-center g-4"> 
            {/* (Using Grid in bootstrap) className'row' will surround columns, 'g-4' inserts gab all around the cards, if vertically use 'gy-4', horizontally use 'gx-4'         */}
           
            {/* OPTIMAL START */}
            <div className="col-lg ">

              <div 
                className="card text-light pricebox" 
                style={{height: "800px", backgroundImage: "linear-gradient(1deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}
              >
                <div className="card-body text-center mt-2">
                  <div className="h1 mb-3">
                    <i className="bi bi-box-fill text-warning me-1"></i>
                    <i className="bi bi-box-fill text-warning me-1"></i>
                    {/* <i className="bi bi-box-fill text-danger me-1"></i> */}
                    <i className="bi bi-box-fill text-warning"></i>
                  </div> 
                  <h3 className="card-title mb-3">
                    Optimal
                  </h3> 

                  <h5 className="card-title mb-3">              
                    Pris fra 1000 kr/md et rum
                  </h5>
                 
                  <div className='text-start'>  
                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Ca. 13m2 lagerlokale i stue etage
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> 2,5 tons palleløfter altid til rådighed
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Ca. 5 meter fra lagerdør til hoved porten
                    </p>   

                     <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Elektronisk lås med kode hoved-porten
                    </p>     

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Adgang både hverdage og i weekender
                    </p> 

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Gode parkeringsmuligheder under lagerbesøg
                    </p>
                  </div>            
                </div>

                <div class="card-footer mt-4">
                  <NavLink to="/contact" className="text-white btn btn-outline-warning btn-sm mt-3">
                  HØR MERE/KONTAKT
                  <i class="bi bi-arrow-right-circle ms-2"></i>
                  </NavLink> 
                  <p className='mt-2'> 
                    <i className="bi-telephone-forward-fill text-warning fs-5 me-3"></i>
                    <span className='me-2'>24 47 59 10</span>  
                  </p> 
                </div>

              </div>              
            </div>
            {/* OPTIMAL END */}

            {/* BASIC START */}
            <div className="col-lg">
              {/* <!--? className 'col' creates column in pre defined row  --> */}
              {/* <!--? className 'col-md' when screen medium scale, then columns will stack over  --> */}
              <div 
                className="card text-light testCard pricebox"
                // style={{height: "700px", backgroundColor: "#474747" }}
                style={{height: "800px", backgroundImage: "linear-gradient(1deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}

              >                
                <div className="card-body text-center mt-2">
                  <div className="h1 mb-3">
                  <i className="bi bi-box-fill"></i>
                  <i className="bi bi-box-fill ms-1"></i>                
                  </div> 
                  <h3 className="card-title mb-3">
                    Basic
                  </h3>  
                  <h5 className="card-title mb-3">              
                    Pris fra 700 kr/md et rum
                  </h5>
                 
                  <div className='text-start'>  
                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Ca. 14 m2 lagerlokale på 1. sal
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> 2,5 tons palleløfter altid i rådighed
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Ca. 2,5 meter fra lagerdør til jern trappe
                    </p>  

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Ca. 4 meter fra lagerdør til hoved-porten
                    </p>   

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Hængelås med kode til hoved porten
                    </p> 

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Adgang både hverdage og i weekender
                    </p> 

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Gode parkeringsmuligheder under lagerbesøg
                    </p>
                  </div>                       
                </div>
                <div 
                  className="card-footer" 
                  // style={{backgroundColor: "#474747"}}
                >
                  <NavLink to="/contact" className="text-white btn btn-outline-warning btn-sm mt-3">
                    HØR MERE/KONTAKT 
                    <i class="bi bi-arrow-right-circle ms-2"></i>
                  </NavLink>
                  <p className='mt-2'> 
                    <i className="bi-telephone-forward-fill text-warning fs-5 me-3"></i>
                    <span className='me-2'>24 47 59 10</span>  
                  </p> 
                </div>

              </div>
            </div>
            {/* BASIC END */}         

            {/* CONTAINER START */}
            <div className="col-lg">
              <div 
                className="card text-light pricebox" 
                // style={{height: "700px", backgroundColor: "#474747"}}
                style={{height: "800px", backgroundImage: "linear-gradient(1deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}
              >
                <div className="card-body text-center mt-2">
                  <div className="h1 mb-3">
                  <i className="bi bi-box-fill text-info"></i>
                  <i className="bi bi-box-fill text-info ms-1"></i>                     
                  </div> 
                  <h3 className="card-title mb-3">              
                    Container
                  </h3>  

                  <h5 className="card-title mb-3">              
                    Pris fra 1600 kr/md en 40 fods container
                  </h5>
                 
                  <div className='text-start'>  
                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Ca. 25m2 lagerplads
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Vandtæt, robust 40 fods HC container
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Mulighed adgang til strøm
                    </p>

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> Nem adgang og store vend plads foran containeren
                    </p>      

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i> AAdgang både hverdage og i weekender
                    </p> 

                    <p>
                      <i className="bi bi-check-circle-fill fs-5 me-2 text-danger"></i>  Gode parkeringsmuligheder under lagerbesøg
                    </p>
                  </div>                  
                </div>

                <div class="card-footer">
                  <NavLink to="/contact" className="text-white btn btn-outline-warning btn-sm mt-3">
                    HØR MERE/KONTAKT
                    <i class="bi bi-arrow-right-circle ms-2"></i>
                  </NavLink> 
                  <p className='mt-2'> 
                    <i className="bi-telephone-forward-fill text-warning fs-5 me-3"></i>
                    <span className='me-2'>24 47 59 10</span>  
                  </p>
                  
                </div> 
              </div>                                         
            </div> 
            {/* CONTAINER END */}

            {/* PARKING START */}
            <div className='d-flex justify-content-center'>
             <div 
              className="card col-lg text-light"
              style={{backgroundImage: "linear-gradient(260deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}           
              >
              <div className="card-body text-start p-4 pricebox">
              <p className="card-title mb-3 fs-3">Parkerings Priser</p>

                <p className=''>
                  <i className="bi bi-p-circle me-2 text-danger fs-4"></i> Almindelige bil 300 kr. / mdr.
                </p>
                <p>
                  <i className="bi bi-p-circle me-2 text-danger fs-4"></i>Trailer 3 meter 300 kr. / mdr.
                </p>

                <p>
                  <i className="bi bi-p-circle me-2 text-danger fs-4"></i>Trailer 3-6 meter 500 kr. / mdr.
                </p>

                <p>
                  <i className="bi bi-p-circle me-2 text-danger fs-4"></i>Trailer 6-12 meter 800 kr. / mdr.
                </p>

                <p>
                  <i className="bi bi-p-circle me-2 text-danger fs-4"></i>Container 6 meter 600 kr. / mdr. 
                </p>

                <p>
                  <i className="bi bi-p-circle me-2 text-danger fs-4"></i>Container 12 meter 800 kr. / mdr. 
                </p>
                
                <p>
                  <span className='text-warning'>Obs!</span> Hvis du selv kommer med en container, har vi krav til containeres farve og tilstand, som vurderes fra gang til gang. 
                </p>                  

              </div>
            </div>
            </div>
            {/* PARKING END */}  

            {/* LOCATION INFO */}
            <div className="d-flex justify-content-center">
              <div 
                className="card col-lg text-light"
                style={{width: "800px", backgroundImage: " linear-gradient(260deg, rgba(85, 85, 85, 0.7), rgba(0, 0, 0, 1))" }}
              >
                <div className="card-body text-start p-4 pricebox">
                  <p>
                    <i className="bi bi-sign-merge-right me-2 text-success fs-4"></i>Tæt på Motorvej 21 
                  </p>
                
                  <p>
                    <i className="bi bi-train-front me-2 text-success fs-4"></i>Tæt på Høje Taastrup Station
                  </p>

                  <p>
                    <i className="bi bi-cart-check me-2 text-success fs-4"></i>Tæt på f.eks IKEA, PLANTORAMA, RACE HALL, CITY 2  i Taastrup
                  </p>

                  <p>
                    <i className="bi bi-bank me-2 text-success fs-4"></i>Ca. 25 km til København Centrum
                  </p>

                </div>
              </div>
            </div>
             {/* LOCATION END*/}

             {/* QUICK INFO */}
            <div className="d-flex justify-content-center mb-4">
              <div 
                className="card col-lg text-light"
                style={{width: "800px", backgroundImage: "linear-gradient(269deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}
              >
                <div className="card-body text-start pricebox p-4">
                  <p className="card-title mb-3 fs-4">Info</p>
                    <p>
                      <i className="bi bi-info-circle me-2 text-info  "></i> 3 mdrs depositum og 1 mdrs forudbetalt leje
                    </p>
                    <p>
                      <i className="bi bi-info-circle me-2 text-info  "></i> 3 mdrs opsigelses 
                    </p>
                    <p>
                      <i className="bi bi-info-circle me-2 text-info"></i>Leje kontrakter kan laves til enten erhvervs eller privatkunder
                    </p>
                    <p>
                      <i className="bi bi-info-circle me-2 text-info"></i>Alle priser er netto, eksklusive moms
                    </p>
                  <div className='text-center'>
                    <NavLink to="/info" className="btn btn-outline-warning btn-sm">                      
                      LÆS MERE 
                      <i class="bi bi-arrow-right-circle-fill ms-2"></i></NavLink>
                  </div>
                    
                </div>
              </div>
            </div>
            {/* QUICK END */}           

          </div>
        </div>        
      </section>
      
    </div>
    
  )
}
