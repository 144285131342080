import React from 'react'
import { NavLink } from 'react-router-dom'
// import IMAGES from '../assets/images/images.js'
import heroimg1 from '../assets/images/2023_lager_i05.jpg'
import heroimg2 from '../assets/images/2023_lager_laden.JPG'
import heroimg3 from '../assets/images/heroimg_1c.jpg'
// import fishInBowl from '../assets/images/2023_fish_in_bowl.png'
// import moveHouse from '../assets/images/2023_flytning.jpg'
// import lageri05 from '../assets/images/fclager2.png'
// import lagerrobot from '../assets/images/2023_lager_robot.jpg'

export default function FclagerHome() {
  return (

    <div className='bghome p-1'>

       <div className='container mt-3'>

        <h1 className='heroheader1 text-white text-center p-4 '> 
          LAGER   
        </h1>  

        <h1 className='heroheader2 text-white text-center'> 
         I HØJE TAASTRUP - KØBENHAVN   
        </h1> 

        <h1 className='heroheader3 text-white text-center lead fs-1 p-3'> 
          OPBEVARINGSRUM, DEPOTRUM, OPMAGASINERING   
        </h1>  

        <h1 className='herophone text-white text-center p-3'> 
          <i class="bi bi-telephone-forward-fill me-2 text-warning"></i> 24 47 59 10          
        </h1>
        
        <div class="d-grid col-3 mx-auto text-center">
          <NavLink to="/contact" className="text-white text-center btn btn-outline-warning fs-4"> 

            <i class="bi bi-envelope-at-fill text-center"></i>
            <p className='lead fs-4'>Send besked</p>         
          </NavLink>
        </div>

        <img src={heroimg1} alt="" className='heroimg text-center'/> 

        <div className=''> 

          <div className='d-flex flex-column text-light text-center lead fs-3 '> 
                
            <p className='rounded'>
              <i class="bi bi-truck"></i> Nem adgang
            </p>

            <p className='rounded'>
            <i class="bi bi-sign-merge-right"></i> Tæt på motorvej 21 
            </p>

            <p className=' rounded'>
            <i class="bi bi-bank"></i> 16 km til Roskilde og 25 km til København
            </p>
                      
          </div>

          <img src={heroimg3} alt="" className='heroimg text-center'/> 
      
          <div className='d-flex flex-column text-light text-center lead fs-3'>
            <p className='rounded'>
              <i class="bi bi-telephone-forward-fill"></i> 24 47 59 10
            </p>
            <p className='rounded'>
              <i class="bi bi-geo-alt-fill"></i> Pilevangsdal 1C, Sengeløse DK-2630 Høje Taastrup 
            </p>
          </div>

          <img src={heroimg2} alt="" className='heroimg text-center'/> 

        </div>  
      </div>          
    </div>  
 
  )
}
