import React from 'react'
import IMAGES from '../assets/images/images.js'

export default function FclagerGallery() {

  return (

    <div className='container my-3 '>
      <div id="carouselExampleIndicators" className="carousel slide">  

        <div className="carousel-indicators">

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1">          
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="5" aria-label="Slide 6">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="6" aria-label="Slide 7">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="7" aria-label="Slide 8">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="8" aria-label="Slide 9">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="9" aria-label="Slide 10">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="10" aria-label="Slide 11">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="11" aria-label="Slide 12">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="12" aria-label="Slide 13">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="13" aria-label="Slide 14">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="14" aria-label="Slide 15">        
          </button>

          <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="15" aria-label="Slide 16">        
          </button>

        </div>
        <div className="carousel-inner">

          <div className="carousel-item active">
            <img src={IMAGES.img_800}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_805}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_100}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_105}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_110}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_115}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_120}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_125}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_130}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_135}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_900}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_905}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_910}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_915}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_920}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>

          <div className="carousel-item">
            <img src={IMAGES.img_810}  className="d-block w-100 img_wrapper" alt="..."/>
          </div>



        </div>

        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div> 
    </div>   
  )
}
