import React from 'react'
import logoPetekDesign from '../assets/images/2023_logo_petek_p_black.png'
import {NavLink} from 'react-router-dom'
// import {Link} from 'react-router-dom'

export default function FclagerFooter() {
  
  return (

    <footer className='p-3 text-white text-center '>
      <div className="container text-center p-2" >
        {/* Find a proper solution */}
        <a href="#" className="text-white lead fw-bold"> 
        <i className="bi bi-arrow-up-circle h2"></i> 
        </a> 
      </div>      

      <div className='footernav'>
        <p>
          <NavLink to="/" style={({isActive}) => {return{color: isActive? 'yellow': ''}}}        
          >Hjem </NavLink>
          <NavLink to="/pricing" style={({isActive}) => {return{color: isActive? 'yellow': ''}}}
          >Priser</NavLink>
          <NavLink to="/gallery" style={({isActive}) => {return{color: isActive? 'yellow': ''}}}
          >Galleri</NavLink>
          <NavLink to="/about" style={({isActive}) => {return{color: isActive? 'yellow': ''}}}
          >Om os</NavLink>
          <NavLink to="/contact" style={({isActive}) => {return{color: isActive? 'yellow': ''}}}
          >Kontakt</NavLink> 
        </p>
      </div> 

      <div className='conatiner fs-5 p-3'>
        <NavLink to="https://www.facebook.com/" className="bi bi-facebook text-white mx-2"></NavLink>  
        <NavLink to="https://twitter.com/" className="bi bi-twitter text-white mx-2"></NavLink>
        <NavLink to="https://www.linkedin.com/" className="bi bi-linkedin text-white mx-2"></NavLink>
        <NavLink to="https://www.instagram.com/" className="bi bi-instagram text-white mx-2"></NavLink>
      </div>


      <div className="container">
        <p>
          <i class="bi bi-telephone-forward-fill "></i> 24 47 59 10
          <i class="bi bi-envelope-at-fill text-center ms-4"></i>
          <NavLink 
            to="mailto:fclagerlokaler@gmail.com, harunws@gmail.com"
            className=""
            style={{textDecoration: 'none', color:'white'}}
            > fclagerlokaler@gmail.com        
          </NavLink>
          <i class="bi bi-geo-alt-fill ms-4"></i> Pilevangsdal 1C, Sengeløse DK-2630 Høje Taastrup 
        </p>      
      </div> 

      <div className="container">
        <p>Copyright &copy; {new Date().getFullYear()} <span> 
          <img 
            src={logoPetekDesign} 
            alt="" 
          style={{ width: "30px", height: "30px", padding: "5px"}}
          /> </span>Petek Design</p>      
      </div> 

  </footer>
  )
}
