import React, {useState} from 'react'
import {send} from 'emailjs-com'
import { useNavigate, NavLink } from 'react-router-dom';
import validator from 'validator'

export default function FclagerContact() {

  const navigate = useNavigate();

  // ? Email validation
  const [emailError, setEmailError] = useState('')
  const validateEmail = (e) => {
    let email = e.target.value   
    
    if (validator.isEmail(email)) {
      setEmailError('')
    } else {
      setEmailError('Email uguldig! Skriv venligst en guldige Email')
    } 
  }
    
  const [toSend, setToSend] = useState({
    from_name: 'Harun',
    user_name:'',   
    to_name: '',
    message: '',
    reply_to: '',
  });

  const onSubmit = (e) => {
    e.preventDefault();
    send(
      'service_d2zduvj',
      'template_qu4ipxn',
      toSend,
      '8UoqQu-TyokmYv3ik'
    )
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        navigate(`/email_sent`);
      })
      .catch((err) => {
        console.log('FAILED...', err);
      });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
 
  return (
    <div>     

      <div className='container my-4'>
        <p className='fs-1'>Kontakt os</p>
        <p className='lead fw-semibold'>
          Flisecentrum v/Harun Sen - CVR-nummer: 29334706
        </p>
        <p className='lead'>Brug kontaktformularen her på siden, ring til os på 
          <span className='fw-bold  ms-1'> 24 47 59 10</span> eller send os en mail på 
          <span className='mx-2'>
            <NavLink 
              to="mailto:fclagerlokaler@gmail.com, harunws@gmail.com"
              className="fw-semibold"
              style={{textDecoration: 'none', color:'blue'}}
              >fclagerlokaler@gmail.com        
            </NavLink>
          </span>         
          Vi bestræber os på at besvare din henvendelse indenfor 1-2 hverdage. 
        </p>
     
        <form 
          className='form-btn-group-sm mt-4'          
          onSubmit={onSubmit}
        >

          <div className="mb-3" id="contact-form">

            <div 
              className='contactbox p-5 rounded-2 text-light'
              style={{backgroundImage: " linear-gradient(269deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1 ))" }}
            >
              <label htmlFor="exampleInputEmail1" className="form-label">Fra</label>
              <input 
                type="text" 
                className="form-control mb-3"
                placeholder='Dit fulde navn'
                name='user_name'
                value={toSend.user_name}
                onChange={handleChange}
                required                       
              />

              <label htmlFor="exampleInputEmail1" className="form-label mt-2">Mobil</label>
              <input 
                type="text" 
                className="form-control mb-3 "
                placeholder='Telefon nummer'
                name='to_name'
                value={toSend.to_name}
                onChange={handleChange}
                required
              />  

              <label htmlFor="exampleInputEmail1" className="form-label mt-2">Din email</label>
              <input
                type='text'
                className="form-control mb-3"
                name='reply_to'
                placeholder='Din email'
                value={toSend.reply_to}
                onChange={handleChange}
                required
                onChangeCapture={(e) => validateEmail(e)}    
              />     
              <span                
                style={{color:'red'}}               
              > {emailError}</span>          

              <label htmlFor="exampleInputEmail1" className="form-label mt-2">Besked</label>
              <textarea 
                type="text" 
                className="form-control mb-5"
                placeholder='Skriv din besked here...'
                name='message'
                value={toSend.message}
                onChange={handleChange} 
                required        
                >
                </textarea>      
          
              <div className='container text-center'>
                <button 
                  type="submit" 
                  className="btn btn-outline-warning  px-5 "
                  value="Send"
                >SEND
                </button>
              </div> 
            </div>  
          </div>
          <div 
            className='container contactbox  text-white p-5 mt-4 rounded-2  lead'
            style={{backgroundImage: " linear-gradient(269deg, rgba(85, 85, 85, 0.8), rgba(0, 0, 0, 1))" }}
          >
            <p className='ms-3'>
            <i class="bi bi-layers-fill text-danger me-2"></i>
              Flisecentrum v/Harun Sen - CVR-nummer: 29334706
            </p>
          
            <p  className='ms-3'> <i className="bi bi-file-person text-danger me-2"></i> Harun Sen </p>
            <p  className='ms-3'><i className="bi bi-phone text-danger me-2"></i> 24 47 59 10</p>  

            <p  className='ms-3'>
              <NavLink 
              to="mailto:fclagerlokaler@gmail.com, harunws@gmail.com"
              style={{textDecoration: 'none', color: 'white'}}
              > <i className="bi bi-envelope-at text-danger me-2"></i>
              fclagerlokaler@gmail.com              
              </NavLink> 
            </p>         

            <p  className='ms-3'> 
              <NavLink 
                to="https://www.google.com/maps/place/Flisecentrum+v%2FHarun+Sen/@55.666411,12.254039,17z/data=!3m1!4b1!4m6!3m5!1s0x4652596f20199e4b:0xab5f8c0104c59c4c!8m2!3d55.666411!4d12.256233!16s%2Fg%2F11xbfrzf0"
                style={{textDecoration: 'none', color: 'white'}}
              >
              <i className="bi bi-buildings text-danger me-2"></i> 
                Pilevangsdal 1C, DK-2630 Sengeløse / Høje Taastrup 
              </NavLink>
            </p>
          </div> 

          <div className='container my-4 d-flex justify-content-center'>
            <iframe 
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2250.298226799585!2d12.254038951656451!3d55.666414006023174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4652596f20199e4b%3A0xab5f8c0104c59c4c!2sFlisecentrum%20v%2FHarun%20Sen!5e0!3m2!1sen!2sdk!4v1680528853162!5m2!1sen!2sdk' 
              width='600' 
              height='450' 
              style={{ border:'0'}} 
              allowfullscreen=''
              loading='lazy' 
              referrerpolicy='no-referrer-when-downgrade' 
              title='GoogleMapLink'          
            >
            </iframe>
          </div>
        </form>
      </div>
    </div>
  )
}
