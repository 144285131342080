import React from 'react'
import { Link } from 'react-router-dom'
import imgsentemail from '../assets/images/email_sent_image.JPG'

export default function FclagerEmailSent() {

  return (
    <div className='container-lg col-6 my-5'>
      <div className="card text-center">

        <img src={imgsentemail}className="card-img-top" alt="..."/>

        <div className="card-body 
        
        ">
          <h5 className="card-title lead text-success">Tak for din henvendelse.</h5>
          <p className="card-text">Vi kontatker dig hurtigst mulig.</p>
          <Link to="/" className="btn btn-dark"><i className="bi bi-arrow-left-circle"></i> Hovedside</Link>
        </div>

      </div>
    </div>
  )
}
